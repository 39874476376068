<script setup lang="ts">
import { DcEndPoints, OrganizationUserRole } from "docuchatcommontypes";
import type { TableColumn } from "~/types/nuxtUI";

definePageMeta({
  layout: "app",
  middleware: ["dc-auth"],
});

const { t } = useI18n();
const { currentLink } = useNavigation();
const toast = useToast();

const { user, organization, organizationUsers } = storeToRefs(useUserStore());
const { fetchTeamMembers, isAdmin } = useUserStore();
const { seats, canUseTeamFeatures, canAddSeat } = useLimits();

// Columns and rows
const columns: TableColumn[] = [
  {
    key: "user",
    label: t("user.team.columns.user"),
    sortable: true,
  },
  {
    key: "role",
    label: t("user.team.columns.role"),
    sortable: true,
  },
  {
    key: "status",
    label: t("user.team.columns.status"),
    sortable: true,
  },
  {
    key: "actions",
  },
];

const sort = ref<{ column: string; direction: "asc" | "desc"; }>({
  column: "user",
  direction: "asc",
});

// Table filters
const query = ref("");

const selectedRoles = ref<OrganizationUserRole[]>([]);
const roleOptions = computed(() => {
  if (user.value?.organizationUserRole === OrganizationUserRole.Admin)
    return [OrganizationUserRole.Member];
  else
    return [OrganizationUserRole.Admin, OrganizationUserRole.Member];
});

const selectedStatuses = ref<string[]>([]);
const statusOptions = [t("user.team.status.joined"), t("user.team.status.pending")];

const rows = computed(() => {
  let users = organizationUsers.value?.filter(u => u.emailOrUserName !== user.value?.emailorUserName) ?? [];

  // For admins, only show members
  if (user.value?.organizationUserRole === OrganizationUserRole.Admin)
    users = users?.filter(u => u.organizationUserRole === OrganizationUserRole.Member);

  return users.map(u => ({
    user: u.emailOrUserName,
    role: u.organizationUserRole,
    status: u.hasJoinedOrganization ? t("user.team.status.joined") : t("user.team.status.pending"),
  }));
});

const filteredRows = computed(() => {
  return rows.value.filter((row) => {
    const roleMatch = selectedRoles.value.length === 0 || selectedRoles.value.includes(row.role);
    const statusMatch = selectedStatuses.value.length === 0 || selectedStatuses.value.includes(row.status);
    const queryMatch = row.user.toLowerCase().includes(query.value.toLowerCase());
    return roleMatch && statusMatch && queryMatch;
  });
});

const sortedRows = computed(() => {
  return filteredRows.value.toSorted((a, b) => {
    const column = sort.value.column;
    const direction = sort.value.direction === "asc" ? 1 : -1;

    switch (column) {
      case "user":
        return direction * a.user.localeCompare(b.user);
      case "role":
        return direction * a.role.localeCompare(b.role);
      case "status":
        return direction * a.status.localeCompare(b.status);
      default:
        return 0;
    }
  });
});

function handleSort(column: { column: string; direction: "asc" | "desc"; }) {
  if (sort.value.column === column.column) {
    sort.value.direction = sort.value.direction === "asc" ? "desc" : "asc";
  }
  else {
    sort.value = { column: column.column, direction: "asc" };
  }
}

// Table pagination
const page = ref(1);
const pageCount = 16;
const pageFrom = computed(() => (page.value - 1) * pageCount + 1);
const pageTo = computed(() => Math.min(page.value * pageCount, sortedRows.value.length));
const paginatedRows = computed(() => {
  return sortedRows.value.slice((page.value - 1) * pageCount, (page.value) * pageCount);
});

watch([query, selectedRoles, selectedStatuses, sortedRows], () => {
  page.value = 1;
});

// Add
const showAddModal = ref(false);
const addPending = ref(false);

function handleAddClick() {
  if (!canAddSeat.value) {
    toast.add({
      title: t("user.team.addUser.noSeatAvailable.title"),
      description: t("user.team.addUser.noSeatAvailable.description"),
      color: "red",
      icon: "i-carbon-in-progress-error",
    });
    return;
  }

  showAddModal.value = true;
}

async function handleAdd(users: Array<{ email: string; role: OrganizationUserRole; }>) {
  addPending.value = true;

  try {
    const newUsers = users.map(u => ({ emailOrUserName: u.email, organizationUserRole: u.role }));
    const endpoint = new DcEndPoints.UpsertOrganizationUser({
      organizationId: organization.value!.id,
      users: [...(organizationUsers.value ?? []), ...newUsers],
    });
    const response = await useApi(endpoint);

    if (response.success) {
      toast.add({
        title: t("user.team.addSuccess.title"),
        description: t("user.team.addSuccess.description"),
      });

      if (response.content.credentials)
        downloadAsCsvFile("newDocuchatUsers", response.content.credentials);

      await fetchTeamMembers();
    }
    else {
      throw createError(response.message ?? t("user.team.unknownError"));
    }
  }
  catch (error: any) {
    toast.add({
      title: t("user.team.addError.title"),
      icon: "i-carbon-warning",
      description: error.message,
      color: "red",
    });
  }

  showAddModal.value = false;
  addPending.value = false;
}

// Edit
const showEditModal = ref(false);
const targetForEdit = ref<string>("");
const editPending = ref(false);

async function handleUpdateRole(newRole: OrganizationUserRole) {
  if (!targetForEdit.value)
    throw createError(t("user.team.unknownError"));

  editPending.value = true;

  try {
    const updatedOrganizationusers = organizationUsers.value?.map((u) => {
      if (u.emailOrUserName === targetForEdit.value)
        return { ...u, organizationUserRole: newRole };
      return u;
    });

    if (!updatedOrganizationusers)
      throw createError(t("user.team.unknownError"));

    const endpoint = new DcEndPoints.UpsertOrganizationUser({
      organizationId: organization.value!.id,
      users: updatedOrganizationusers,
    });
    const response = await useApi(endpoint);

    if (!response.success)
      throw createError(response.message ?? t("user.team.unknownError"));

    await fetchTeamMembers();
    showEditModal.value = false;
  }
  catch (error: any) {
    toast.add({
      title: t("user.team.updateRoleError.title"),
      icon: "i-carbon-warning",
      description: error.message,
      color: "red",
    });
  }

  editPending.value = false;
}

// Delete
const showDeleteModal = ref(false);
const targetForDelete = ref<string>("");
const deletePending = ref(false);

async function handleDelete() {
  if (!targetForDelete.value)
    throw createError(t("user.team.unknownError"));

  deletePending.value = true;

  try {
    const updatedOrganizationusers = organizationUsers.value?.filter(u => u.emailOrUserName !== targetForDelete.value);

    if (!updatedOrganizationusers)
      throw createError(t("user.team.unknownError"));

    const endpoint = new DcEndPoints.UpsertOrganizationUser({
      organizationId: organization.value!.id,
      users: updatedOrganizationusers,
    });
    const response = await useApi(endpoint);

    if (!response.success)
      throw createError(response.message ?? t("user.team.unknownError"));

    await fetchTeamMembers();
    showDeleteModal.value = false;
  }
  catch (error: any) {
    toast.add({
      title: t("user.team.deleteError.title"),
      icon: "i-carbon-warning",
      description: error.message,
      color: "red",
    });
  }

  deletePending.value = false;
}

// Roles help
const showRolesHelp = ref(false);
const rolesExplanation = getOrganizationRolesExplanation();

useSeoMeta({
  title: `${t("common.siteName")} - ${t("base.navigation.team.label")}`,
});
</script>

<template>
  <UDashboardPage>
    <UDashboardPanel
      grow
      class="relative"
    >
      <div
        v-if="!canUseTeamFeatures"
        class="absolute inset-0 z-10 p-8"
      >
        <div class="flex h-full flex-col items-center justify-center gap-4">
          <p class="text-lg font-medium">
            Your plan does not support team features. Please upgrade to a plan that does.
          </p>
          <UButton
            variant="solid"
            :label="t('user.team.upgradeButton')"
            to="/user/settings/plan"
          />
        </div>
      </div>
      <div :class="{ 'opacity-40': !canUseTeamFeatures }">
        <UDashboardNavbar>
          <template #title>
            <span class="truncate">{{ currentLink?.label }}</span>
            <UBadge
              v-if="isAdmin"
              :color="canAddSeat ? 'primary' : 'red'"
              variant="subtle"
              size="xs"
            >
              {{ t('user.team.pageTitleSeats', { left: seats.left }) }}
              <UTooltip :text="t('user.team.pageTitleSeatsTooltip')">
                <UButton
                  variant="ghost"
                  :color="canAddSeat ? 'primary' : 'red'"
                  size="2xs"
                  trailing-icon="i-carbon-information"
                  to="/user/settings/plan"
                />
              </UTooltip>
            </UBadge>
          </template>

          <template #right>
            <UButton
              variant="solid"
              :label="t('user.team.addUserButton')"
              trailing-icon="i-carbon-add-large"
              @click.prevent="handleAddClick"
            />
          </template>
        </UDashboardNavbar>

        <UDashboardToolbar>
          <template #left>
            <UInput
              v-model="query"
              icon="i-carbon-search"
              size="xs"
              autocomplete="off"
              :placeholder="t('user.team.searchUsers')"
              class="hidden lg:block"
            />

            <USelectMenu
              v-model="selectedRoles"
              icon="i-carbon-identification"
              size="xs"
              :placeholder="t('user.team.rolePlaceholder')"
              multiple
              :options="roleOptions"
              :ui-menu="{ option: { base: 'capitalize' } }"
            />

            <USelectMenu
              v-model="selectedStatuses"
              icon="i-carbon-email-new"
              size="xs"
              :placeholder="t('user.team.statusPlaceholder')"
              multiple
              :options="statusOptions"
              :ui-menu="{ option: { base: 'capitalize' } }"
            />
          </template>

          <template #right>
            <div class="space-x-3">
              <UButton
                variant="link"
                size="xs"
                :label="t('user.team.learnMoreRoles')"
                @click.prevent="showRolesHelp = true"
              />
            </div>

            <UDashboardModal
              v-model="showRolesHelp"
              :title="t('user.team.rolesModalTitle')"
              :description="t('user.team.rolesModalDescription')"
              icon="i-carbon-identification"
            >
              <DcMarkdown
                size="sm"
                :md="rolesExplanation"
              />
            </UDashboardModal>
          </template>
        </UDashboardToolbar>

        <UDashboardPanelContent>
          <UDashboardCard>
            <UTable
              :columns="columns"
              :rows="paginatedRows"
              :sort="sort"
              sort-asc-icon="i-carbon-sort-ascending"
              sort-desc-icon="i-carbon-sort-descending"
              :sort-button="{
                icon: 'i-carbon-arrows-vertical',
              }"
              :empty-state="{
                icon: 'i-carbon-user-multiple',
                label: t('user.team.emptyStateLabel'),
              }"
              sort-mode="manual"
              @update:sort="handleSort"
            >
              <template #status-data="{ row }">
                <UBadge
                  variant="subtle"
                  :color="row.status === t('user.team.status.joined') ? 'primary' : 'yellow'"
                >
                  {{ row.status }}
                </UBadge>
              </template>

              <template #actions-data="{ row }">
                <div class="flex items-center gap-2">
                  <UTooltip :text="t('user.team.editRoleTooltip')">
                    <UButton
                      variant="solid"
                      size="xs"
                      color="gray"
                      icon="i-carbon-edit"
                      @click.prevent="() => { targetForEdit = row.user; showEditModal = true; }"
                    />
                  </UTooltip>

                  <UTooltip :text="t('user.team.deleteTooltip')">
                    <UButton
                      variant="soft"
                      class="ring-1 ring-inset ring-red-500 ring-opacity-25 dark:ring-red-400 dark:ring-opacity-25"
                      size="xs"
                      color="red"
                      icon="i-carbon-trash-can"
                      @click.prevent="() => { targetForDelete = row.user; showDeleteModal = true; }"
                    />
                  </UTooltip>
                </div>
              </template>
            </UTable>

            <!-- Pagination -->
            <div
              v-if="sortedRows.length > pageCount"
              class="flex flex-wrap items-center justify-between gap-2 border-t border-gray-200 px-3 py-3.5 dark:border-gray-700"
            >
              <div>
                <span
                  class="text-sm leading-5"
                  v-html="t('common.pagination', { from: pageFrom, to: pageTo, total: sortedRows.length })"
                />
              </div>

              <UPagination
                v-model="page"
                :page-count="pageCount"
                :total="sortedRows.length"
              />
            </div>
          </UDashboardCard>
        </UDashboardPanelContent>
      </div>

      <!-- Add Modal -->
      <LazyModalUserAdd
        v-model="showAddModal"
        :loading="addPending"
        :role-options="roleOptions"
        :organization-users="organizationUsers ?? []"
        @on-add="handleAdd"
      />

      <!-- Edit Modal -->
      <LazyModalUserEdit
        v-model="showEditModal"
        :loading="editPending"
        :current-role="rows.find(r => r.user === targetForEdit)?.role"
        :options="roleOptions"
        @on-edit="handleUpdateRole"
      />

      <!-- Delete Confirmation Modal -->
      <LazyModalConfirmationDelete
        v-model="showDeleteModal"
        :loading="deletePending"
        :resource-name="targetForDelete"
        @on-delete="handleDelete"
      />
    </UDashboardPanel>
  </UDashboardPage>
</template>
