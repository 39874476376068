import { DcEndPoints, RequestedChatBots } from "docuchatcommontypes";
import type { DcChatbot } from "docuchatcommontypes";

export const useChatbotStore = defineStore("chatbot", () => {
  const t = useNuxtApp().$i18n.t;

  // Data
  const publicChatbotsFetched = ref(false);
  const publicChatbots = ref<DcChatbot[]>([]);

  const userChatbotsFetched = ref(false);
  const userChatbots = ref<DcChatbot[]>([]);
  const organizationChatbots = ref<DcChatbot[]>([]);
  const favoriteChatbots = ref<DcChatbot[]>([]);

  // Fetch chatbots
  async function fetchPublicChatbots(useCache = false) {
    if (useCache && publicChatbotsFetched.value)
      return;

    const endpoint = new DcEndPoints.GetChatBots({
      requestedBots: RequestedChatBots.PublicChatBots,
    });
    const response = await useApi(endpoint);

    if (response.success) {
      publicChatbots.value = response.content.chatbots;
      publicChatbotsFetched.value = true;
    }
    else {
      throw createError(response.message
        ?? t("common.genericError"));
    }
  }

  async function fetchChatbots(useCache = false) {
    if (useCache && userChatbotsFetched.value)
      return;

    // We ask for chatbots based on user type
    const requestedBots: number = RequestedChatBots.UserChatbots | RequestedChatBots.FavoriteChatbots | RequestedChatBots.OrganizationChatBots;
    const endpoint = new DcEndPoints.GetChatBots({ requestedBots });
    const response = await useApi(endpoint);

    if (response.success) {
      const allChatbots = response.content.chatbots;
      const resUserChatbotIds = response.content.userChatbotIds;
      const resOrganizationChatbotIds = response.content.organizationChatBotIds;
      const resFavoriteChatbotIds = response.content.favoriteChatbotIds;

      userChatbots.value = allChatbots.filter((c: DcChatbot) =>
        resUserChatbotIds.includes(c.id),
      );
      organizationChatbots.value = allChatbots.filter((c: DcChatbot) =>
        resOrganizationChatbotIds.includes(c.id));

      favoriteChatbots.value = allChatbots.filter((c: DcChatbot) =>
        resFavoriteChatbotIds.includes(c.id),
      );

      userChatbotsFetched.value = true;
    }
    else {
      throw createError(response.message
        ?? t("common.genericError"));
    }
  }

  // Helpers
  function getChatbotFromCacheById(chatbotId: string) {
    let chatbot = userChatbots.value.find(chatbot => chatbot.id === chatbotId);
    if (chatbot)
      return chatbot;

    chatbot = organizationChatbots.value.find(chatbot => chatbot.id === chatbotId);
    if (chatbot)
      return chatbot;

    return undefined;
  }

  function getChatbotsFromCacheByIds(chatbotIds: string[]) {
    const chatbots: DcChatbot[] = [];
    chatbotIds.forEach((id) => {
      const chatbot = getChatbotFromCacheById(id);
      if (chatbot)
        chatbots.push(chatbot);
    });
    return chatbots;
  }

  function isFavoriteChatbot(chatbotId: string) {
    return favoriteChatbots.value.some(f => f.id === chatbotId);
  }

  function $reset() {
    publicChatbotsFetched.value = false;
    publicChatbots.value = [];

    userChatbotsFetched.value = false;
    userChatbots.value = [];
    organizationChatbots.value = [];
    favoriteChatbots.value = [];
  }

  return {
    publicChatbots,
    userChatbots,
    organizationChatbots,
    isFavoriteChatbot,

    fetchPublicChatbots,
    fetchChatbots,
    getChatbotFromCacheById,
    getChatbotsFromCacheByIds,

    $reset,
  };
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useChatbotStore, import.meta.hot));
