import { default as _91id_93T0nAUVBOZbMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/chat/[id].vue?macro=true";
import { default as _91id_93BsTqrjV138Meta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/chat/embed/[id].vue?macro=true";
import { default as publicvSOJV3bkmjMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/chat/public.vue?macro=true";
import { default as indexSRNyebd24NMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/index.vue?macro=true";
import { default as maintenanceOVU093WZg7Meta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/maintenance.vue?macro=true";
import { default as sandboxOJ4MMxBbVFMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/sandbox.vue?macro=true";
import { default as signinWlzKrAu53ZMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/signin.vue?macro=true";
import { default as signoutxYrqthmq4SMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/signout.vue?macro=true";
import { default as signupKowhVheehSMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/signup.vue?macro=true";
import { default as index9ZH3jWmqZVMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/analytics/index.vue?macro=true";
import { default as sessionsmkF8pv8QDBMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue?macro=true";
import { default as analyticsdZak4zWHcmMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/analytics.vue?macro=true";
import { default as _91id_93uAgdbvGiAUMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue?macro=true";
import { default as creater13h8rkqxiMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/create.vue?macro=true";
import { default as _91id_9396Ungbdy44Meta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue?macro=true";
import { default as indexMAf3aDmhmUMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/index.vue?macro=true";
import { default as documentskPaqB2rzKOMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/documents.vue?macro=true";
import { default as indexUkvdKri441Meta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/index.vue?macro=true";
import { default as postcheckoutyuEzlC9UfoMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/postcheckout.vue?macro=true";
import { default as resetPasswordzZMhKl6Zb3Meta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/resetPassword.vue?macro=true";
import { default as apihl3F2EDHqTMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/api.vue?macro=true";
import { default as indexMSrNHuGGHZMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/index.vue?macro=true";
import { default as integrationso9In1uhgNiMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/integrations.vue?macro=true";
import { default as legalAv6EX4BrAmMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/legal.vue?macro=true";
import { default as organization9zqZV3yDDoMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/organization.vue?macro=true";
import { default as planpu7FQQdJp9Meta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/plan.vue?macro=true";
import { default as settingsaBzzKzoPJPMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings.vue?macro=true";
import { default as supportV1BqvO5suYMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/support.vue?macro=true";
import { default as teamGOzhwl7XKFMeta } from "/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/team.vue?macro=true";
export default [
  {
    name: "chat-id___en",
    path: "/chat/:id()",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-id___de",
    path: "/de/chat/:id()",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-embed-id___en",
    path: "/chat/embed/:id()",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-embed-id___de",
    path: "/de/chat/embed/:id()",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-public___en",
    path: "/chat/public",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: "chat-public___de",
    path: "/de/chat/public",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en",
    path: "/maintenance",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___de",
    path: "/de/maintenance",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "sandbox___en",
    path: "/sandbox",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "sandbox___de",
    path: "/de/sandbox",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "signin___en",
    path: "/signin",
    meta: signinWlzKrAu53ZMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___de",
    path: "/de/signin",
    meta: signinWlzKrAu53ZMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signout___en",
    path: "/signout",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: "signout___de",
    path: "/de/signout",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: "signup___en",
    path: "/signup",
    meta: signupKowhVheehSMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "signup___de",
    path: "/de/signup",
    meta: signupKowhVheehSMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: analyticsdZak4zWHcmMeta?.name,
    path: "/user/analytics",
    meta: analyticsdZak4zWHcmMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "user-analytics___en",
    path: "",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "user-analytics-sessions___en",
    path: "sessions",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: analyticsdZak4zWHcmMeta?.name,
    path: "/de/user/analytics",
    meta: analyticsdZak4zWHcmMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "user-analytics___de",
    path: "",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "user-analytics-sessions___de",
    path: "sessions",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-chatbots-chat-id___en",
    path: "/user/chatbots/chat/:id()",
    meta: _91id_93uAgdbvGiAUMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-chat-id___de",
    path: "/de/user/chatbots/chat/:id()",
    meta: _91id_93uAgdbvGiAUMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-create___en",
    path: "/user/chatbots/create",
    meta: creater13h8rkqxiMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-create___de",
    path: "/de/user/chatbots/create",
    meta: creater13h8rkqxiMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-edit-id___en",
    path: "/user/chatbots/edit/:id()",
    meta: _91id_9396Ungbdy44Meta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-edit-id___de",
    path: "/de/user/chatbots/edit/:id()",
    meta: _91id_9396Ungbdy44Meta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots___en",
    path: "/user/chatbots",
    meta: indexMAf3aDmhmUMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots___de",
    path: "/de/user/chatbots",
    meta: indexMAf3aDmhmUMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: "user-documents___en",
    path: "/user/documents",
    meta: documentskPaqB2rzKOMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: "user-documents___de",
    path: "/de/user/documents",
    meta: documentskPaqB2rzKOMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: "user___en",
    path: "/user",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user___de",
    path: "/de/user",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-postcheckout___en",
    path: "/user/postcheckout",
    meta: postcheckoutyuEzlC9UfoMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "user-postcheckout___de",
    path: "/de/user/postcheckout",
    meta: postcheckoutyuEzlC9UfoMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "user-resetPassword___en",
    path: "/user/resetPassword",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "user-resetPassword___de",
    path: "/de/user/resetPassword",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: settingsaBzzKzoPJPMeta?.name,
    path: "/user/settings",
    meta: settingsaBzzKzoPJPMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "user-settings-api___en",
    path: "api",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/api.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___en",
    path: "",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-integrations___en",
    path: "integrations",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-legal___en",
    path: "legal",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-organization___en",
    path: "organization",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-plan___en",
    path: "plan",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsaBzzKzoPJPMeta?.name,
    path: "/de/user/settings",
    meta: settingsaBzzKzoPJPMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "user-settings-api___de",
    path: "api",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/api.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___de",
    path: "",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-integrations___de",
    path: "integrations",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-legal___de",
    path: "legal",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-organization___de",
    path: "organization",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-plan___de",
    path: "plan",
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-support___en",
    path: "/user/support",
    meta: supportV1BqvO5suYMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: "user-support___de",
    path: "/de/user/support",
    meta: supportV1BqvO5suYMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: "user-team___en",
    path: "/user/team",
    meta: teamGOzhwl7XKFMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  },
  {
    name: "user-team___de",
    path: "/de/user/team",
    meta: teamGOzhwl7XKFMeta || {},
    component: () => import("/codebuild/output/src953564726/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  }
]