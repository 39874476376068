<script lang="ts" setup>
import { DcEndPoints } from "docuchatcommontypes";
import { DcEvent } from "@/types/posthog";

const { $sentry, $posthog } = useNuxtApp();
const { t } = useI18n();
const toast = useToast();
const { user, isAdmin, isOnTrial } = storeToRefs(useUserStore());
const { organizationChatbots: chatbots } = storeToRefs(useChatbotStore());
const { questionCredits } = useLimits();

const readDocs = useLocalStorage("onboarding-read-docs", false);
const tasks = computed(() => [
  {
    title: t("user.onboarding.tasks.createChatbot.title"),
    description: t("user.onboarding.tasks.createChatbot.description"),
    completed: chatbots.value?.length > 0,
    action: () => navigateTo("/user/chatbots/create"),
  },
  {
    title: t("user.onboarding.tasks.editChatbot.title"),
    description: t("user.onboarding.tasks.editChatbot.description"),
    completed: chatbots.value.some(chatbot => chatbot.answers.customModelInstructions?.length > 0),
    action: () => {
      const chatbot = chatbots.value[0];
      if (chatbot) {
        navigateTo(`/user/chatbots/edit/${chatbot.id}`);
      }
    },
  },
  {
    title: t("user.onboarding.tasks.askQuestion.title"),
    description: t("user.onboarding.tasks.askQuestion.description"),
    completed: questionCredits.value.used > 0,
    action: () => {
      const chatbot = chatbots.value[0];
      if (chatbot) {
        navigateTo(`/user/chatbots/chat/${chatbot.id}`);
      }
    },
  },
  {
    title: t("user.onboarding.tasks.shareChatbot.title"),
    description: t("user.onboarding.tasks.shareChatbot.description"),
    completed: chatbots.value.some(c => c.sharing.team.enabled || c.sharing.link.enabled || c.sharing.embed.enabled),
    action: () => {
      const chatbot = chatbots.value[0];
      if (chatbot) {
        navigateTo(`/user/chatbots/edit/${chatbot.id}?share=true`);
      }
    },
  },
  {
    title: t("user.onboarding.tasks.readDocs.title"),
    description: t("user.onboarding.tasks.readDocs.description"),
    completed: readDocs.value,
    action: () => {
      readDocs.value = true;
      navigateTo("https://docs.docuchat.io", { external: true });
    },
  },
]);

// Modals
const showAlert = computed(() => isAdmin.value && !user.value?.completedOnBoarding && tasks.value.some(t => !t.completed));
const showAlertModal = ref(showAlert.value);
const showSuccessModal = ref(false);

watch(tasks, async () => {
  // If this is the first time the user has completed all tasks
  if (tasks.value.every(t => t.completed) && !user.value?.completedOnBoarding) {
    showSuccessModal.value = true;
    throwConfetti();
  }
}, { immediate: true });

// Handlers
function runTaskActions(task: typeof tasks.value[number]) {
  showAlertModal.value = false;
  toast.add({ title: task.description, timeout: 8000 });
  $posthog.capture(DcEvent.OnboardingActionUsed, { action: task.title });
  task.action();
}

const { copy, copied } = useClipboard();
const couponCode = "AWMDI5MA";

async function handleComplete() {
  copy(couponCode);
  showSuccessModal.value = false;

  // Update the user's onboarding status in the database
  try {
    const endpoint = new DcEndPoints.SetCompletedOnBoarding({ completed: true });
    const res = await useApi(endpoint);

    if (!res.success) {
      throw new Error("Failed to update onboarding status");
    }
  }
  catch (error) {
    $sentry.captureError(error);
  }
}

async function handleClose() {
  // Optimistically update the user's onboarding status
  if (user.value) {
    user.value.completedOnBoarding = true;
  }

  try {
    // Update the user's onboarding status in the database
    const endpoint = new DcEndPoints.SetCompletedOnBoarding({ completed: true });
    const res = await useApi(endpoint);

    if (!res.success) {
      throw new Error("Failed to update onboarding status");
    }
  }
  catch (error) {
    $sentry.captureError(error);
  }
}
</script>

<template>
  <div v-auto-animate>
    <!-- In Menu Reminder Alert -->
    <div v-if="showAlert">
      <UDivider
        class="mb-3"
        type="dashed"
      />
      <UAlert
        color="sky"
        variant="subtle"
        :title="`${t('user.onboarding.emoji')} ${isOnTrial ? t('user.onboarding.trialUser.title') : t('user.onboarding.otherUser.title')}`"
        :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'sky', variant: 'link', padded: false }"
        :ui="{
          title: 'text-xs font-semibold',
          description: 'text-xs',
        }"
        @close="handleClose"
      >
        <template #description>
          <p>
            {{ isOnTrial ? t("user.onboarding.trialUser.description") : t("user.onboarding.otherUser.description") }}
          </p>
          <ul class="mt-2 flex flex-col gap-0.5">
            <li
              v-for="(task, idx) in tasks"
              :key="idx"
              class="flex items-start gap-1"
              :class="{
                'pointer-events-none opacity-70': task.completed || idx > 0 && !tasks[idx - 1].completed,
              }"
            >
              <UIcon
                :name="task.completed ? 'i-carbon-checkbox-checked-filled' : 'i-carbon-checkbox'"
                class="size-4 shrink-0"
              />
              <span
                class="cursor-pointer hover:underline"
                :class="{ 'line-through': task.completed }"
                @click.prevent="runTaskActions(task)"
              >
                {{ task.title }}
              </span>
            </li>
          </ul>
        </template>
      </UAlert>
    </div>

    <!-- Onboarding Modal -->
    <UDashboardModal
      v-model="showAlertModal"
      :title="`${t('user.onboarding.modalPrefix')} ${isOnTrial ? t('user.onboarding.trialUser.title') : t('user.onboarding.otherUser.title')}`"
      :ui="{
        title: 'text-xl',
        body: {
          padding: 'pt-0 sm:pt-0',
        } as any,
      }"
    >
      <template #icon>
        <Logo
          class="mt-1 h-auto w-6 shrink-0"
          icon-only
        />
      </template>

      <NuxtPicture
        src="/images/welcome.jpg"
        :img-attrs="{ class: 'rounded-lg h-64 w-full object-cover ring-1 ring-gray-300 dark:ring-gray-700' }"
      />

      <DcMarkdown
        class="mt-2"
        :md="isOnTrial ? t('user.onboarding.trialUser.description') : t('user.onboarding.otherUser.description')"
      />

      <UAlert
        color="primary"
        variant="subtle"
      >
        <template #description>
          <ul class="mt-1 flex flex-col gap-1.5 text-base">
            <li
              v-for="(task, idx) in tasks"
              :key="idx"
              class="flex items-start gap-2"
              :class="{ 'text-primary pointer-events-none opacity-70': task.completed }"
            >
              <UIcon
                :name="task.completed ? 'i-carbon-checkbox-checked-filled' : 'i-carbon-checkbox'"
                class="mt-0.5 size-5 shrink-0"
              />
              <span
                class="cursor-pointer hover:underline"
                :class="{ 'line-through': task.completed }"
                @click.prevent="runTaskActions(task)"
              >
                {{ task.title }}
              </span>
            </li>
          </ul>
        </template>
      </UAlert>

      <template #footer>
        <UButton
          variant="solid"
          :label="t('user.onboarding.closeButtonLabel')"
          block
          @click.prevent="showAlertModal = false"
        />
      </template>
    </UDashboardModal>

    <!-- Onboarding Success Modal -->
    <UDashboardModal
      v-model="showSuccessModal"
      :title="t('user.onboarding.success.title')"
      :close-button="null"
      :prevent-close="true"
      :ui="{
        title: 'text-xl',
        body: {
          padding: 'pt-0 sm:pt-0',
        } as any,
      }"
    >
      <DcMarkdown :md="t('user.onboarding.success.description')" />

      <UAlert
        v-if="isOnTrial"
        icon="i-carbon-shopping-bag"
        color="primary"
        variant="subtle"
        :title="t('user.onboarding.success.coupon.title')"
      >
        <template #description>
          <DcMarkdown
            size="sm"
            class="text-primary"
            :md="t('user.onboarding.success.coupon.description')"
          />

          <div class="mt-2">
            <UButtonGroup
              size="xs"
              class="w-full"
            >
              <UInput
                :model-value="couponCode"
                disabled
              />
              <UButton
                color="primary"
                :icon="!copied ? 'i-carbon-copy' : 'i-carbon-checkmark-outline'"
                @click.prevent="copy(couponCode)"
              />
            </UButtonGroup>
          </div>
        </template>
      </UAlert>

      <DcMarkdown
        class="mt-2"
        :md="t('user.onboarding.success.postDescription')"
      />

      <template #footer>
        <UButton
          variant="solid"
          :label="isOnTrial ? t('user.onboarding.success.coupon.closeButtonLabel') : t('user.onboarding.closeButtonLabel')"
          block
          @click.prevent="handleComplete"
        />
      </template>
    </UDashboardModal>
  </div>
</template>
