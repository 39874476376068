import { DcEndPoints } from "docuchatcommontypes";

export function useAuthentication() {
  const t = useNuxtApp().$i18n.t;
  const client = useAuth();

  interface AuthResponse {
    ok: boolean;
    error: null | SignInErrorTypes;
  }

  type SignInErrorTypes =
    | "Signin"
    | "OAuthSignin"
    | "OAuthCallback"
    | "OAuthCreateAccount"
    | "EmailCreateAccount"
    | "Callback"
    | "OAuthAccountNotLinked"
    | "EmailSignin"
    | "CredentialsSignin"
    | "SessionRequired"
    | "default";

  async function signUpWithEmail(email: string, password: string) {
    isSignUpEnabled();
    const endpoint = new DcEndPoints.Signup({ email, password });
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError({
        message: response.message ?? t("common.genericError"),
      });
    }
  }

  async function signInWithEmail(username: string, password: string) {
    const res = await client.signIn("credentials", { username, password, redirect: false });
    const typedRes = res as unknown as AuthResponse;

    if (typedRes.error)
      throw createError(getSignInErrorMessage(typedRes.error));
  }

  async function signInWithGoogle() {
    isSignUpEnabled();

    const res = await client.signIn("google", { redirect: false });
    const typedRes = res as unknown as AuthResponse;

    if (typedRes.error)
      throw createError(getSignInErrorMessage(typedRes.error));
  }

  async function signOut() {
    await client.signOut({ redirect: false });
  }

  // Reset password
  async function sendResetPasswordEmail(email: string) {
    const endpoint = new DcEndPoints.SendResetPasswordEmail({ email });
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError({
        message: response.message ?? t("common.genericError"),
      });
    }

    return response.message;
  }

  async function validateResetPasswordToken(token: string) {
    const endpoint = new DcEndPoints.IsPasswordResetTokenValid({ token });
    const response = await useApi(endpoint);

    if (response.success)
      return { isValid: true, message: "" };
    else
      return { isValid: false, message: response.message ?? t("common.genericError") };
  }

  async function resetPassword(token: string, newPassword: string) {
    const endpoint = new DcEndPoints.ResetPassword({ token, newPassword });
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError({
        message: response.message ?? t("common.genericError"),
      });
    }
  }

  // Helpers
  async function checkIfUserExists(email: string) {
    const lowerCaseEmail = email.toLowerCase();
    const endpoint = new DcEndPoints.DoesUserExist({ emailOrUserName: lowerCaseEmail });
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError({
        message: response.message ?? t("common.genericError"),
      });
    }

    return { exists: response.content.exists, correctedEmail: response.content.correctedEmail };
  }

  function isSignUpEnabled() {
    if (useRuntimeConfig().public.maintenance.disableSignUp)
      throw createError(t("welcome.signUp.disabled"));
  }

  function getSignInErrorMessage(error: SignInErrorTypes) {
    let message = import.meta.dev ? `[${error}] ` : "";

    switch (error) {
      case "CredentialsSignin":
      case "Signin":
        message += t("welcome.signIn.errors.incorrectCredentials");
        break;
      case "OAuthSignin":
      case "OAuthCallback":
      case "EmailCreateAccount":
      case "OAuthCreateAccount":
      case "Callback":
        message += t("welcome.signIn.errors.differentAccount");
        break;
      case "OAuthAccountNotLinked":
        message += t("welcome.signIn.errors.originalAccount");
        break;
      case "EmailSignin":
        message += t("welcome.signIn.errors.emailFailed");
        break;
      case "SessionRequired":
        message += t("welcome.signIn.errors.sessionRequired");
        break;
      default:
        message += t("welcome.signIn.errors.unknown");
        break;
    }

    return message;
  }

  return {
    signInWithEmail,
    signUpWithEmail,
    signInWithGoogle,
    signOut,
    validateResetPasswordToken,
    sendResetPasswordEmail,
    resetPassword,
    checkIfUserExists,
  };
}
